<!--
NOTES:

  1. Inconsistency in component parameter naming/grouping

    when calling getPolicyCountReport, the setup of the params is not consistent.
    when the hasPolicyValues (actually stateFilter) is set up,
    some filter values are in this.filters.*
    while others don't

  2. Big bloated class

    separate HTML, CSS and TS code
    (actually custom CSS should be minimum)

  3. Enhancement

    any way to bind the useSimpleReport = true | false from the URI?

-->
<template>
  <div v-if="$hasPermissions(clientSession, ['CLIENT_REPORTS'], 1)">
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-show="isLoading">
        <Loading />
      </div>
      <div style="padding: 4px; height: 100%; width: 100%!important;">

        <!-- Filters Section -->
        <div class="filters-section">
          <div class="columns is-multiline is-desktop is-fullwidth">
            <div class="column is-full-mobile is-narrow">
              <b-field label="Month End Date">
                <b-datepicker v-model="formattedDate"
                placeholder="Select a date" editable
                :date-parser="customDateParser"
                style="max-width: 132px;"
                :date-formatter="customDateFormatter">
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-full-mobile is-one-fifth-desktop">
              <b-field label="Carrier">
                <multiselect
                  v-model="filters.selectedCarriersArray"
                  :options="carriers"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="All Carriers"
                  :loading="carriersLoading"
                  @select="fetchClients"
                  @remove="fetchClients"
                  label="label"
                  track-by="value">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-show="values.length > 0 && !isOpen">{{ values.length }} carriers selected </span>
                  </template>
                  <template slot="clear">
                    <div class="multiselect__clear" v-if="filters.selectedCarriersArray.length" @mousedown.prevent.stop="() => (filters.selectedCarriersArray = [])">
                      <i class="fas fa-times"></i>
                    </div>
                  </template>
                </multiselect>
              </b-field>
            </div>
            <div class="column is-full-mobile">
              <b-field label="Client">
                <multiselect
                  v-model="filters.selectedClientsArray"
                  :options="clients"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :loading="clientsLoading"
                  :preserve-search="true"
                  placeholder="All Clients"
                  label="label"
                  track-by="value">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"  v-show="values.length > 0 && !isOpen">{{ values.length }} clients selected</span>
                  </template>
                  <template slot="clear">
                    <div class="multiselect__clear" v-if="filters.selectedClientsArray.length" @mousedown.prevent.stop="() => (filters.selectedClientsArray = [])">
                      <i class="fas fa-times"></i>
                    </div>
                  </template>
                </multiselect>
              </b-field>
            </div>
            <div class="column is-full-mobile is-narrow">
              <b-field label="Policy Values">
                <b-select v-model="filters.hasPolicyValues" placeholder="Select Policy Values">
                  <option v-for="value in policyValues" :key="value.value" :value="value.value">
                    {{ value.text }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-full-mobile is-narrow is-flex is-align-items-bottom">
              <div class="control is-flex" style="align-items: end; padding-bottom: 6px;">
                <button class="button is-accent" @click="applyFilters">View Report</button>
              </div>
            </div>
          </div>
        </div>

        <Grid
          v-if="!isLoading"
          class="custom-grid"
          :data-items="policyCountMatch.value"
          :sortable="true"
          :pageable="{
            pageSizes: [15, 50, 100]
          }"
          :columns="columns"
          :page-size="pagination.pageSize"
          :skip="pagination.skip"
          :total="policyCountMatch.count"
          @pagechange="onPageChange"
          @sortchange="sortChangeHandler"
          :sort="sort"
          :style="{ maxHeight: 'none', height: 'auto' }"
        >
        </Grid>

      </div>
    </div>
  </div>
  <div v-else>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">No Permission</h1>
    </div>
    <div class="has-text-centered ">
      <b>You Lack The Permissions Required To View This Page</b>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { Grid } from '@progress/kendo-vue-grid'
import Loading from '../Loading'
import moment from 'moment'

const pageName = 'Policy Count Match Report'

export default {
  components: {
    Loading,
    Grid
  },
  data () {
    return {
      useSimpleReport: true,
      pageheading: pageName,
      isLoading: true,
      clientsLoading: true,
      carriersLoading: true,
      policyCountMatch: {
        summary: {},
        count: 0,
        value: []
      },
      carriers: [],
      clients: [],
      sort: [{ field: 'clientID', dir: 'asc' }],
      filters: {
        selectedClientsArray: [],
        selectedCarriersArray: [],
        hasPolicyValues: 0,
        monthEndDate: null
      },
      policyValues: [
        { text: 'All Values', value: 0 },
        { text: 'No Exception', value: 1 },
        // { text: 'Partial Exception', value: 2 },
        { text: 'Exception', value: 3 }
      ],
      pagination: {
        take: 10,
        skip: 0,
        total: 0,
        pageSize: 10
      },
      dateString: null
    }
  },
  methods: {
    applyFilters () {
      this.fetchPolicyCountMatchReport()
    },
    getFormattedDate (date) {
      return new Date(date).toLocaleDateString('en-US')
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value)
    },
    async fetchPolicyCountMatchReport () {
      this.isLoading = true
      try {
        const params = {
          monthEndDateStr: this.dateString,
          carrierFilter: this.selectedCarriers,
          clientFilter: this.selectedClients,
          stateFilter: this.filters.hasPolicyValues,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortField: this.sortObject.field,
          sortDirection: this.sortObject.dir
        }

        const res = this.useSimpleReport
          ? await this.api().getPolicyCountSimpleReport(params)
          : await this.api().getPolicyCountReport(params)

        if (!res.isInputError) {
          this.policyCountMatch = res
          this.policyCountMatch.summary = this.policyCountMatch.summary || {}
          if (this.policyCountMatch.summary != null) this.dateString = this.policyCountMatch.summary.monthEndDate
        }
      } catch (error) {
        console.error('Error fetching client exceptions:', error)
        this.errorToast('Failed to fetch client exceptions.')
      }
      this.isLoading = false
    },
    async fetchClients () {
      this.clientsLoading = true
      try {
        var result = await this.api().getClientsToPolicies(this.selectedCarriers)
        this.clients = result.map(client => ({
          value: client.id,
          label: client.id + ' - ' + client.name
        }))
      } catch (error) {
        console.error('Error fetching clients:', error)
        this.errorToast('Failed to fetch clients.')
      }
      this.clientsLoading = false
    },
    async fetchCarriers () {
      this.carriersLoading = true
      try {
        var result = await this.api().getCarriers()
        this.carriers = result.map(carrier => ({
          value: carrier.code,
          label: carrier.code
        }))
      } catch (error) {
        console.error('Error fetching carriers:', error)
        this.errorToast('Failed to fetch carriers.')
      }
      this.carriersLoading = false
    },
    onPageChange ({ page }) {
      if (this.pagination.pageSize !== page.take) {
        this.pagination.pageSize = page.take
      }
      this.pagination.skip = page.skip
      this.pagination.pageSize = page.take
      this.pagination.page = (page.skip / page.take) + 1
      this.fetchPolicyCountMatchReport()
    },
    sortChangeHandler ({ sort }) {
      this.sort = sort
      this.fetchPolicyCountMatchReport()
    },
    customDateParser (dateString) {
      const date = moment.utc(dateString, 'YYYY-MM-DD', true)
      return date.isValid() ? date.toDate() : null
    },
    customDateFormatter (date) {
      return date ? moment.utc(date).format('YYYY-MM-DD') : ''
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    selectedClients () {
      return this.filters.selectedClientsArray.length > 0
        ? this.filters.selectedClientsArray.map(client => client.value).join(',') : ''
    },
    selectedCarriers () {
      return this.filters.selectedCarriersArray.length > 0
        ? this.filters.selectedCarriersArray.map(carrier => carrier.value).join(',') : ''
    },
    sortObject () {
      let sort = { field: 'clientID', dir: 'asc' }
      if (this.sort.length === 1) {
        sort = this.sort[0]
      }
      return sort
    },
    formattedDate: {
      get () {
        return this.dateString ? new Date(this.dateString) : null
      },
      set (value) {
        if (value) {
          const year = value.getFullYear()
          const month = String(value.getMonth() + 1).padStart(2, '0') // month is zero-indexed
          const day = String(value.getDate()).padStart(2, '0')
          this.dateString = `${year}-${month}-${day}`
        } else {
          this.dateString = null
        }
      }
    },
    columns () {
      if (this.useSimpleReport) {
        return [
          {
            field: 'clientId',
            title: 'Client ID',
            width: '94px',
            sortable: true
          },
          {
            field: 'clientName',
            title: 'Client Name',
            sortable: true
          },
          {
            field: 'carrierCode',
            title: 'Carrier',
            width: '86px',
            sortable: true
          },
          {
            title: 'Policies',
            children: [
              { field: 'policyCount', title: 'Active Policies', sortable: true, width: '110px' },
              { field: 'recordCount', title: 'Policy Values This Month', sortable: true, width: '110px' },
              { field: 'missingCount', title: 'Missing', sortable: true, width: '110px' }
              // { field: 'duplicateCount', title: 'Duplicates', sortable: true, width: '110px' }
            ]
          }
        ]
      } else {
        return [
          {
            field: 'clientId',
            title: 'Client ID',
            width: '94px',
            sortable: true
          },
          {
            field: 'clientName',
            title: 'Client Name',
            sortable: true
          },
          {
            field: 'carrierCode',
            title: 'Carrier',
            width: '86px',
            sortable: true
          },
          {
            title: 'Policies',
            children: [
              { field: 'policyInactiveCount', title: 'Inactive', sortable: true, width: '110px' },
              { field: 'policyActiveCount', title: 'Active', sortable: true, width: '110px' }
            ]
          },
          {
            title: 'Policy count',
            children: [
              { field: 'policyValuesPreviousCount', title: 'Last Month', sortable: true, width: '110px' },
              { field: 'policiesDeactivated', title: 'Deactivated', sortable: true, width: '110px' },
              { field: 'policiesActivated', title: 'Activated', sortable: true, width: '110px' },
              { field: 'policyValuesCount', title: 'This Month', sortable: true, width: '110px' },
              { field: 'policyValuesProjectionCount', title: 'This Month (projection)', sortable: true, width: '110px' },
              { field: 'missing', title: 'Variance', sortable: true, width: '110px' }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.pagination.pageSize = 15
    this.fetchCarriers()
    this.fetchClients()
    this.fetchPolicyCountMatchReport()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags{
  min-height: 36px;
  height: 36px;
  padding: 6px 40px 0 6px;
}

.multiselect__clear {
    position: absolute;
    right: 14px;
    height: 35px;
    width: 40px;
    display: flex;
    cursor: pointer;
    z-index: 3;
    align-items: center;
    color: #888;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #1f314d;
    right: 1.125em;
    z-index: 30;
}

.filters-section .select select, .select:not(.is-multiple), .button, .input{
  height: 2.2em;
}

.custom-grid .k-header {
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    font-size: 0.8rem;

    background-color: #9FA8DA;
    color: white;
}
</style>

<style scoped>
  .filters-section {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem
  }
</style>
